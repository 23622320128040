'use client';
import { cn } from '@v2/utils/cn';
import { VariantProps, cva } from 'class-variance-authority';
import {
  InputHTMLAttributes,
  PropsWithChildren,
  forwardRef,
  useState,
} from 'react';
import { Button } from '../Button';
import { AlertCircleRed, Eye, EyeOff } from '../Icon';

export const inputCva = cva(
  'leading-[24px] text-[16px] font-normal focus:outline-none appearance-none border border-gray-300 focus:border-gray-800  text-gray-800 rounded-lg w-full placeholder-gray-500',
  {
    variants: {
      extent: {
        sm: 'px-3 py-2  h-10',

        md: 'px-3.5 py-2.5  h-12',
        lg: 'p-4 h-14',
      },
    },
    defaultVariants: {
      extent: 'sm',
    },
  }
);

export type InputProps = VariantProps<typeof inputCva> & {
  errorText?: string;
  isError?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  containerClassName?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { className, errorText, isError, extent, startIcon, endIcon, ...props },
    ref
  ) => {
    const { disabled, type = 'text' } = props;
    const [showPassword, setShowPassword] = useState(false);

    return (
      <div className="flex w-full flex-col gap-y-1.5">
        <div className="relative">
          {startIcon && (
            <div className="absolute left-4 top-1/2 -translate-y-1/2 transform">
              {startIcon}
            </div>
          )}
          <input
            {...props}
            ref={ref}
            disabled={disabled}
            type={type === 'password' && showPassword ? 'text' : type}
            className={cn(
              inputCva({ extent }),
              { disabled: 'bg-gray-50' },
              isError && 'border-primary-300',
              startIcon ? 'pl-[48px]' : '',

              endIcon && 'pr-10',
              className
            )}
          />
          {endIcon && (
            <div className="absolute right-2 top-1/2 -translate-y-1/2 transform">
              {endIcon}
            </div>
          )}

          {isError && type !== 'password' && (
            <div className="absolute right-0 top-0 flex h-full items-center justify-center px-3 hover:bg-transparent">
              <AlertCircleRed size="16" viewBox="0 0 16 16" />
            </div>
          )}
          {type === 'password' && (
            <Button
              type="button"
              variant="ghost"
              size="sm"
              className="absolute right-0 top-0 h-full px-3 py-2 hover:bg-transparent"
              onClick={() => {
                setShowPassword((prev) => !prev);
              }}
              disabled={disabled}
            >
              {showPassword && !disabled ? (
                <Eye className="h-4 w-4" aria-hidden="true" />
              ) : (
                <EyeOff className="h-4 w-4" aria-hidden="true" />
              )}
              <span className="sr-only">
                {showPassword ? 'Hide password' : 'Show password'}
              </span>
            </Button>
          )}
        </div>

        {isError && <ErrorText>{errorText}</ErrorText>}
      </div>
    );
  }
);

export function ErrorText(props: PropsWithChildren) {
  return <small className="text-[14px] text-[#F04438]">{props.children}</small>;
}
